import Vue from "vue";
import Vuex from "vuex";
import web3 from "@/store/modules/web3.module"
import ui from "@/store/modules/ui.module"
import tokens from "@/store/modules/tokens.module"
import contracts from "@/store/modules/contracts.module"
import pools from "@/store/modules/pools.module"
import governance from "@/store/modules/governance.module"
import voting from "@/store/modules/voting.module"
import stepper from "@/store/modules/stepper.module"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    web3,
    ui,
    tokens,
    contracts,
    pools,
    governance,
    voting,
    stepper
  },
})
