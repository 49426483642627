import Vue from 'vue';
import {INIT_WEB_3} from "@/store/modules/web3.module";

//action types
export const INIT_UI = 'initUi'
export const LOADING_UI = 'loadingUi'

//mutation types
export const SET_UI_STATE = 'setUiState'

const state = {
  init: false,
  loading: true
};

const mutations = {
  [SET_UI_STATE](state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key]);
    });
  }
};

const actions = {
  [INIT_UI]: async ({commit, dispatch}) => {
    commit(SET_UI_STATE, {loading: true});
    await dispatch(INIT_WEB_3)
    // commit(SET_UI_STATE, {loading: false, init: true});
    commit(SET_UI_STATE, {loading: false})
  },
  [LOADING_UI]: async ({commit}, payload) => {
    commit(SET_UI_STATE, {loading: payload});
  }
};

export default {
  state,
  mutations,
  actions
};
