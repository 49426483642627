import Vue from 'vue';
// import ApiService from "@/store/services/api.service";
import {mathRound} from "@/helpers/utils"
import liquifiTokens from "@/data/lists/liquifi.tokenlist.json"
import abi from "@/data/abi/erc20.json"
import ApiService from "@/store/services/api.service";
import {BASE_TOKENS} from "@/constants/baseTokens";

//action types
export const HTTP_GET_TOKENS = 'httpGetTokens'
export const GET_WALLET_BALANCES = 'getWalletBalances'
export const LOAD_TOKENS_LISTS = 'loadTokensLists'
export const LOAD_TOKENS_FROM_SELECTED_LIST = 'loadTokensFromSelectedList'
export const PRELOAD_BASE_TOKEN = 'preloadBaseToken'

//mutation types
export const SET_TOKENS_STATE = 'setTokensState'
export const SET_TOKENS_TOKENS = 'setTokensTokens'
export const ADD_TOKENS_BALANCE = 'addTokensBalance'
export const CLEAR_TOKENS_BALANCES = 'clearTokenBalances'
export const SET_BASE_TOKEN = 'setBaseToken'

const state = {
  tokens: {},
  balances: {},
  tokensLists: {},
  selectedList: 'liquifi.tokenlist',
  baseToken: {}
}
const getters = {
  getWalletBalance: (state, getters) => symbol => {
    // eslint-disable-next-line no-prototype-builtins
    return state.balances.hasOwnProperty(symbol)
      ? mathRound(getters.convertFromWei(state.balances[symbol], state.tokens[symbol].decimals), 8)
      : 0
  },
  getBaseToken: state => {
    return Object.keys(state.baseToken).length === 0 ? null : state.baseToken
  },
  checkBaseToken: (state, getters) => str => {
    return getters.getBaseToken.symbol === str
  }
}
const actions = {
  async [LOAD_TOKENS_LISTS]({commit}) {
    //TODO refactor this to get lists & work with LocalStorage
    await new Promise(resolve => {
      commit(SET_TOKENS_STATE,
        {tokensLists: {"liquifi.tokenlist": liquifiTokens}},
      )
      resolve()
    })
  },
  async [LOAD_TOKENS_FROM_SELECTED_LIST]({state, rootState, commit}) {
    await new Promise(resolve => {
      const tList = state.tokensLists[state.selectedList]
      const filteredTList = tList.tokens.filter(t => t.chainId === rootState.web3.networkId)
      commit(SET_TOKENS_TOKENS, filteredTList)
      resolve()
    })
  },
  async [HTTP_GET_TOKENS]() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get('', 'tokens')
        .then(({data}) => {
          // commit(SET_TOKENS_TOKENS, data)
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [GET_WALLET_BALANCES]({state, rootState, commit}) {
    commit(CLEAR_TOKENS_BALANCES)
    await Object.keys(state.tokens).forEach((async (key) => {
      const contract = await new rootState.web3.web3Instance.eth.Contract(
        abi,
        state.tokens[key]['address']
      )
      await contract.methods.balanceOf(rootState.web3.coinbase).call()
        .then(async function (bal) {
          commit(ADD_TOKENS_BALANCE, {
            balance: bal,
            symbol: key
          })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
        })
    }));
  },
  async [PRELOAD_BASE_TOKEN]({commit}, data) {
    const networkId = +data
    const baseTokens = BASE_TOKENS
    if ([56, 97].includes(networkId)) {
      commit(SET_BASE_TOKEN, baseTokens.bnb)
    } else {
      commit(SET_BASE_TOKEN, baseTokens.eth)
    }
  }
}
const mutations = {
  [SET_TOKENS_STATE](state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key]);
    });
  },
  [SET_TOKENS_TOKENS](state, payload) {
    state.tokens = {}
    Object.keys(payload).forEach(key => {
      Vue.set(state.tokens, payload[key].symbol, payload[key]);
    });
  },
  [CLEAR_TOKENS_BALANCES](state) {
    Vue.set(state, 'balances', {})
  },
  [ADD_TOKENS_BALANCE](state, payload) {
    Vue.set(state.balances, payload.symbol, payload.balance);
  },
  [SET_BASE_TOKEN](state, payload) {
    Vue.set(state, 'baseToken', payload)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
