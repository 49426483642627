import Vue from "vue"
import poolRegisterAbi from "@/data/abi/PoolRegister.json"
import poolFactoryAbi from "@/data/abi/PoolFactory.json"
import activityMeter from "@/data/abi/ActivityMeter.json"
import governor from "@/data/abi/LiquifiInitialGovernor.json"
import lqfGiveaway from "@/data/abi/LQFGiveaway.json";

import ApiService from "@/store/services/api.service";

//mutation types
export const SET_DEFAULT_CONTRACTS = 'setDefaultContracts';
export const SET_CONTRACT_METADATA = 'setContractMetadata';

//action types
export const INIT_CONTRACTS = 'initContracts';
export const HTTP_GET_CONTRACT_METADATA = 'httpGetContractMetadata';

export const CONTRACT_NAMES = {
  LQF_GIVEAWAY: 'lqf_giveaway'
};

const LQF_GIVEAWAY_CONTRACT = {
  address: process.env.VUE_APP_LQF_GIVEAWAY_CONTRACT_ADDR,
  abi: lqfGiveaway.abi
};

const store = {
  pool_register: {
    address: String,
    abi: Array
  },
  pool_factory: {
    address: String,
    abi: Array
  },
  activity_meter: {
    address: String,
    abi: Array
  },
  governor: {
    address: String,
    abi: Array
  },
  minter: {
    address: String,
    abi: Array
  },
  [CONTRACT_NAMES.LQF_GIVEAWAY]: {
    address: String,
    abi: Array
  },
};

const actions = {
  [INIT_CONTRACTS]({commit}) {
    commit(SET_DEFAULT_CONTRACTS);
  },

  async [HTTP_GET_CONTRACT_METADATA]({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.get('', 'contract_metadata')
        .then(({data}) => {
          commit(SET_CONTRACT_METADATA, data)
          resolve(data)
        })
        .catch(({response}) => reject(response))
    })
  }
};

const mutations = {
  [SET_DEFAULT_CONTRACTS](state) {

    Vue.set(state, CONTRACT_NAMES.LQF_GIVEAWAY, LQF_GIVEAWAY_CONTRACT);
  },

  [SET_CONTRACT_METADATA](state, payload) {
    //TODO REMOVE
    if (!payload.activity_meter) {
      payload.activity_meter = {}
    }

    payload.pool_register.abi = poolRegisterAbi.abi
    payload.pool_factory.abi = poolFactoryAbi.abi
    payload.activity_meter.abi = activityMeter.abi
    payload.governor.abi = governor.abi
    payload.minter.abi = []

    Vue.set(state, 'pool_register', payload.pool_register)
    Vue.set(state, 'pool_factory', payload.pool_factory)
    Vue.set(state, 'activity_meter', payload.activity_meter)
    Vue.set(state, 'governor', payload.governor)
    Vue.set(state, 'minter', payload.minter)
  }
};

export default {
  store,
  actions,
  mutations
}
