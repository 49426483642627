import Vue from "vue";

//action types
export const INIT_STEP = 'initStep'
export const SET_STEPS = 'setSteps'
export const NEXT_STEP = 'nextStep'
export const UPDATE_ACTIVE_STEP = 'updateActiveStep'
export const DELETE_STEP_BY_ID = 'deleteStepById'

//mutation types
export const CREATE_NEW_STEP = 'createNewStep'
export const ADD_STEP = 'addStep'
export const SET_NEXT_STEP = 'setNextStep'
export const SET_UPDATE_ACTIVE_STEP = 'setUpdateStepProps'
export const DELETE_STEP = 'deleteStep'

export const STEP_STRUCTURE = {
  action: '',
  token: '',
  txHash: '',
  errorMessage: '',
  status: ''
}

const state = {
  steps: {}
}

const getters = {
  // eslint-disable-next-line no-prototype-builtins
  isStepExists: (state) => idx => state.steps.hasOwnProperty(idx),
  getStepsById: (state) => idx => state.steps[idx],
  getActiveItemByStepId: (state) => idx => {
    const undefinedStepIndex = state.steps[idx].findIndex(_ => _.status === 'undefined')
    if (undefinedStepIndex > -1) {
      return state.steps[idx][undefinedStepIndex - 1]
    }
    return state.steps[idx][state.steps[idx].length - 1]
  }
}

const actions = {
  [INIT_STEP]: ({commit}, data) => {
    commit(CREATE_NEW_STEP, {idx: data});
  },
  [SET_STEPS]: ({commit, getters}, data) => {
    if (getters.isStepExists(data.idx)) {
      data.steps.forEach(step => {
        commit(ADD_STEP, {idx: data.idx, step: step})
      })
    }
  },
  [NEXT_STEP]: ({commit, getters}, data) => {
    if (getters.isStepExists(data.idx)) {
      commit(SET_NEXT_STEP, {idx: data.idx});
    }
  },
  [UPDATE_ACTIVE_STEP]: ({commit, getters}, data) => {
    if (getters.isStepExists(data.idx)) {
      commit(SET_UPDATE_ACTIVE_STEP, {idx: data.idx, step: data.step})
    }
  },
  [DELETE_STEP_BY_ID]: ({commit}, data) => {
    commit(DELETE_STEP, {idx: data});
  },
}

const mutations = {
  [CREATE_NEW_STEP](state, payload) {
    Vue.set(state.steps, payload.idx, [])
  },
  [ADD_STEP](state, payload) {
    state.steps[payload.idx].push(payload.step)
  },
  [SET_NEXT_STEP](state, payload) {
    const activeStepIndex = state.steps[payload.idx].findIndex(_ => _.status === 'active')
    Vue.set(state.steps[payload.idx][activeStepIndex], 'status', 'success')
    Vue.set(state.steps[payload.idx][activeStepIndex + 1], 'status', 'active')
  },
  [SET_UPDATE_ACTIVE_STEP](state, payload) {
    const index = state.steps[payload.idx].findIndex(_ => _.status === 'active');
    if (index > -1) {
      Vue.set(state.steps[payload.idx], index, Object.assign(state.steps[payload.idx][index], payload.step))
    }
  },
  [DELETE_STEP](state, payload) {
    Vue.delete(state.steps, payload.idx)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
