import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/bind',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/bind',
          name: 'bind',
          component: () => import('@/view/pages/Bind'),
          props: true
        },
        {
          path: '/liquidity/pool',
          name: 'liquidity-pool',
          component: () => import('@/view/pages/Liquidity'),
          props: true
        },
        {
          path: '/liquidity',
          name: 'liquidity',
          component: () => import('@/view/pages/Liquidity'),
        },
        {
          path: '/governance',
          // name: 'governance',
          component: () => import('@/view/pages/governance/Governance'),
          children: [
            {
              path: '/',
              redirect: 'lqf-tokens',
            },
            {
              path: 'lqf-tokens',
              name: 'lqf-tokens',
              component: () => import('@/view/pages/governance/LqfTokens')
            },
            {
              path: 'lqf-tokens/deposit-pool',
              name: 'deposit-pool',
              component: () => import('@/view/pages/governance/LqfTokens'),
              props: true
            },
            {
              path: 'voting',
              name: 'voting',
              component: () => import('@/view/pages/governance/Voting')
            },
            {
              path: 'voting/proposal/:id',
              name: 'voting/proposal',
              component: () => import('@/view/pages/governance/Proposal'),
              props: true
            }
          ]
        },
        {
          path: '/dashboard',
          // name: 'dashboard',
          component: () => import('@/view/pages/dashboard/Dashboard'),
          children: [
            {
              path: '/',
              redirect: 'arbitrage',
            },
            {
              path: 'arbitrage',
              name: 'arbitrage',
              component: () => import('@/view/pages/dashboard/Arbitrage')
            },
            {
              path: 'tokens',
              name: 'tokens',
              component: () => import('@/view/pages/dashboard/Tokens')
            },
            {
              path: 'pools',
              name: 'pools',
              component: () => import('@/view/pages/dashboard/Pools')
            },
            {
              path: 'transactions',
              name: 'transactions',
              component: () => import('@/view/pages/dashboard/Transactions')
            },
          ]
        },
      ]
    }
  ]
})
