import BigNumber from "bignumber.js";
import NETWORKS from "./networks.json"

export function mathRound(number, decimalPlaces = 8) {
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(number * factorOfTen) / factorOfTen
}

export function _dateNowPlusEstimatedSeconds(time = 3600) {
  return Math.floor(Date.now() / 1000) + time
}

export function _dateNowPlusHourSeconds() {
  return _dateNowPlusEstimatedSeconds()
}

export function findTokenByAddress(address, store) {
  let SELECTED_LIST = store.state.tokens.selectedList
  let TOKENS_LISTS = store.state.tokens.tokensLists
  let TOKENS = TOKENS_LISTS[SELECTED_LIST]['tokens']
  return TOKENS.find(t => t.address.toLowerCase() === address.toLowerCase())
}

export function getLSConnector() {
  return localStorage.getItem('connector')
}

export function setLSConnector(connector) {
  return localStorage.setItem('connector', connector)
}

export function removeLSConnector() {
  return localStorage.removeItem('connector')
}

export async function checkAllowance(contract, owner, spender, amount) {
  const approvedBalance = await contract.methods.allowance(owner, spender).call()
  if (new BigNumber(approvedBalance).gte(amount)) {
    return false
  }
  return ((new BigNumber(2).pow(256)).minus(1))
    .integerValue(BigNumber.ROUND_DOWN)
    .toFixed()
}


export function getOptionsFromNetworkName(networkName){
  let chosenNetwork = null
  Object.keys(NETWORKS).forEach((key, index) => {
    console.debug(index)
    if(NETWORKS[key].network === networkName) {
      chosenNetwork = NETWORKS[key]
    }
  })
  return chosenNetwork
}
//
// export function checkBaseToken(str, baseTokenSymbol) {
//   return baseTokenSymbol === str.toUpperCase()
// }
