import Vue from "vue"
import ApiService from "@/store/services/api.service";
import erc20 from "@/data/abi/erc20.json";
import BigNumber from "bignumber.js";
import {NEXT_STEP, SET_STEPS, UPDATE_ACTIVE_STEP} from "@/store/modules/stepper.module";
import {checkAllowance} from "@/helpers/utils";

//action types
export const HTTP_GET_LQF_DATA = 'httpGetLqfData'
export const CLAIM_LQF_TOKEN = 'claimLqfToken'
export const DEPOSIT_LQF_TOKENS = 'depositLqfTokens'
export const WITHDRAW_LQF_TOKENS = 'withdrawLqfTokens'
//mutation types
export const SET_GOVERNANCE_STATE = 'setGovernanceState'

function _toBN(num, dec) {
  // 1  Number -> BigNumber
  // 2  right side removal instead of rounding (or round with DOWN parameter)
  // 3  after calculations before send data to contract methods we need to take only integer value explicitly
  // TODO avoid troubles with Number switching to BigNumber for every operation
  return BigNumber(BigNumber(num).multipliedBy(Math.pow(10, Number(dec)))).integerValue(BigNumber.ROUND_DOWN)
}

const state = {
  lqf_balance: 0,
  unclaimed_lqf_tokens: 0,
  first_lqf_calculate_date: 0,
  lqf_minting_step: 0,
  next_lqf_claim_date: 0,
  pools: []
}
const getters = {
  getPoolByTokenPair: state => pair => {
    const inPool = [pair[0], pair[1]]
    let result = state.pools.filter(item =>
      [...new Set([...item.pair, ...inPool])].length === 2)[0]
    if (!result) {
      result = state.pools.filter(item => item.id === state.id)[0]
    }
    return result
  }
}
const actions = {
  async [HTTP_GET_LQF_DATA]({commit, rootState}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if(rootState.web3.coinbase === null) {
        reject('No provider found')
        return
      }
      await ApiService.get(`/providers/${rootState.web3.coinbase}`, 'lqf_data')
        .then(({data}) => {
          commit(SET_GOVERNANCE_STATE, data)
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [CLAIM_LQF_TOKEN]({rootState, dispatch}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = rootState.web3.coinbase
      const ACTIVITY_METER = rootState.contracts.activity_meter;

      const steps = [
        {action: 'Transaction', status: 'active'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const contract = await new rootState.web3.web3Instance.eth.Contract(ACTIVITY_METER.abi, ACTIVITY_METER.address)
        await contract.methods.actualizeUserPools()
          .send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [DEPOSIT_LQF_TOKENS]({rootState, dispatch}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = rootState.web3.coinbase
      const ACTIVITY_METER = rootState.contracts.activity_meter;

      const steps = [
        {action: 'Approve', token: data.symbol, status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const contract = await new rootState.web3.web3Instance.eth.Contract(ACTIVITY_METER.abi, ACTIVITY_METER.address)

        const poolContract = await new rootState.web3.web3Instance.eth.Contract(erc20, data.pool.id)
        const contractDecimals = await poolContract.methods.decimals().call()
        const amount = _toBN(data.amount, contractDecimals).valueOf()

        const rs = await checkAllowance(poolContract, WALLET, ACTIVITY_METER.address, amount, contractDecimals)
        if (rs) {
          await poolContract.methods.approve(ACTIVITY_METER.address, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        await contract.methods.deposit(
          data.pool.id,
          amount
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
          resolve(tx);
        })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [WITHDRAW_LQF_TOKENS]({rootState, dispatch}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = rootState.web3.coinbase
      const ACTIVITY_METER = rootState.contracts.activity_meter;

      const steps = [
        {action: 'Transaction', status: 'active'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const contract = await new rootState.web3.web3Instance.eth.Contract(ACTIVITY_METER.abi, ACTIVITY_METER.address)

        const poolContract = await new rootState.web3.web3Instance.eth.Contract(erc20, data.pool.id)
        const contractDecimals = await poolContract.methods.decimals().call()

        const amount = _toBN(data.amount, contractDecimals).toString()

        await contract.methods.withdraw(
          data.pool.id,
          amount
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
          resolve(tx);
        })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
}
const mutations = {
  [SET_GOVERNANCE_STATE](state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key])
    });
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
