import ApiService from "@/store/services/api.service";
import erc20 from "@/data/abi/erc20.json";
import LiquifiProposal from "@/data/abi/LiquifiProposal.json";
import {NEXT_STEP, SET_STEPS, UPDATE_ACTIVE_STEP} from "@/store/modules/stepper.module";
import {checkAllowance} from "@/helpers/utils";

export const HTTP_GET_GOVERNANCE_VOTING = 'httpGetGovernanceVoting'
export const HTTP_GET_PROPOSAL_BY_ID = 'httpGetProposalById'
export const HTTP_GET_CHECK_IS_VOTED = 'httpGetCheckIsVoted'
export const CREATE_PROPOSAL = 'createProposal'
export const MINTER_BALANCE_OF = 'minterBalanceOf'
export const MINTER_TOTAL_SUPPLY = 'minterTotalSupply'
export const VOTE = 'vote'
export const VOTE_FINALIZE = 'voteFinalize'

const state = {
  current: null
}
const getters = {}
const actions = {
  // eslint-disable-next-line no-empty-pattern
  async [HTTP_GET_GOVERNANCE_VOTING]({}, params) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.query('governance/voting', {params})
        .then(({data}) => {
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  async [HTTP_GET_PROPOSAL_BY_ID]({}, params) {
    // governance/proposal/0x87210f36dcaad71add178621e98466afb6b10e15/data
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get(`governance/proposal/${params.proposal_id}`, `data`)
        .then(({data}) => {
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [HTTP_GET_CHECK_IS_VOTED]({rootState}, extParams) {
    const params = {
      account_id: rootState.web3.coinbase
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.query(`governance/proposal/${extParams.proposal_id}/vote`, {params})
        .then(({data}) => {
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [CREATE_PROPOSAL]({rootState, dispatch}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = rootState.web3.coinbase
      const GOVERNOR = rootState.contracts.governor;
      const MINTER = rootState.contracts.minter;

      const steps = [
        {action: 'Approve', token: 'LQF', status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})
      try {
        const minterContract = await new rootState.web3.web3Instance.eth.Contract(erc20, MINTER.address)
        const contractDecimals = await minterContract.methods.decimals().call()
        const amount = (Number(data.amount)
          * Math.pow(10, Number(contractDecimals))).toLocaleString('fullwide', {useGrouping: false})

        const rs = await checkAllowance(minterContract, WALLET, GOVERNOR.address, amount, contractDecimals)
        if (rs) {
          await minterContract.methods.approve(GOVERNOR.address, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        const contract = await new rootState.web3.web3Instance.eth.Contract(GOVERNOR.abi, GOVERNOR.address)
        await contract.methods.createProposal(
          '', //_proposal
          1, //_option
          0, //_newValue
          data.address, //_address
          '0x0000000000000000000000000000000000000000' //_address2
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
          resolve(tx);
        })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [MINTER_BALANCE_OF]({rootState}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const MINTER = rootState.contracts.minter;
      const minterContract = await new rootState.web3.web3Instance.eth.Contract(erc20, MINTER.address)

      try {
        await minterContract.methods.balanceOf(rootState.web3.coinbase).call()
          .then(function (bal) {
            resolve(bal);
          })

      } catch (e) {
        reject(e);
      }
    })
  },
  async [MINTER_TOTAL_SUPPLY]({rootState}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const MINTER = rootState.contracts.minter;
      const minterContract = await new rootState.web3.web3Instance.eth.Contract(erc20, MINTER.address)

      try {
        await minterContract.methods.totalSupply().call()
          .then(function (res) {
            resolve(res);
          })
      } catch (e) {
        reject(e);
      }
    })
  },
  async [VOTE]({rootState, dispatch}, params) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const steps = [
        {action: 'Approve', token: 'LQF', status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: params.stepUuid, steps: steps})

      const WALLET = rootState.web3.coinbase
      const LIQUIFI_PROPOSAL = LiquifiProposal;
      const proposalContract = await new rootState.web3.web3Instance.eth.Contract(
        LIQUIFI_PROPOSAL.abi,
        params.proposal_id
      )
      const GOVERNOR = rootState.contracts.governor;
      const MINTER = rootState.contracts.minter;
      const minterContract = await new rootState.web3.web3Instance.eth.Contract(erc20, MINTER.address)
      const totalBalance = await dispatch(MINTER_BALANCE_OF)
      try {
        const rs = await checkAllowance(minterContract, WALLET, GOVERNOR.address, totalBalance, 18)
        if (rs) {
          await minterContract.methods.approve(GOVERNOR.address, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: params.stepUuid})

        await proposalContract.methods.vote(params.vote)
          .send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {txHash: hash}})
          })
          .then(function (res) {
            resolve(res);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [VOTE_FINALIZE]({rootState, dispatch}, params) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = rootState.web3.coinbase
      const GOVERNOR = rootState.contracts.governor;
      const LIQUIFI_PROPOSAL = LiquifiProposal;

      const steps = [
        {action: 'Transaction', status: 'active'},
        {action: 'Withdraw', token: 'LQF'},

      ]
      dispatch(SET_STEPS, {idx: params.stepUuid, steps: steps})

      const proposalContract = await new rootState.web3.web3Instance.eth.Contract(
        LIQUIFI_PROPOSAL.abi,
        params.proposal_id
      )
      const governorContract = await new rootState.web3.web3Instance.eth.Contract(GOVERNOR.abi, GOVERNOR.address)
      try {
        await proposalContract.methods.finalize()
          .send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {txHash: hash}})
          })
          .then(async function (res) {
            dispatch(SET_STEPS, {idx: params.stepUuid, steps: steps})
            await governorContract.methods.withdrawAll()
              .on('transactionHash', async function (hash) {
                dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {txHash: hash}})
              })
              .send({from: WALLET})
            resolve(res);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: params.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
}
const mutations = {}


export default {
  state,
  getters,
  actions,
  mutations
}
