export function detectBinanceProvider({
                                        mustBeBinance = false,
                                        silent = false,
                                        timeout = 3000,
                                      } = {}) {
  _validateInputs()

  let handled = false

  return new Promise((resolve) => {
    if (window.BinanceChain) {

      handleBinanceChain()

    } else {

      window.addEventListener(
        'binance#initialized',
        handleBinanceChain,
        { once: true },
      )

      setTimeout(() => {
        handleBinanceChain()
      }, timeout)
    }

    function handleBinanceChain () {

      if (handled) {
        return
      }
      handled = true

      window.removeEventListener('binance#initialized', handleBinanceChain)

      const { BinanceChain } = window

      if (BinanceChain && (!mustBeBinance)) {
        resolve(BinanceChain)
      } else {

        const message = mustBeBinance && BinanceChain
          ? 'Non-MetaMask window.BinanceChain detected.'
          : 'Unable to detect window.BinanceChain.'

        !silent && console.error('Binance connector:', message)
        resolve(null)
      }
    }
  })

  function _validateInputs () {
    if (typeof mustBeBinance !== 'boolean') {
      throw new Error(`Binance connector: Expected option 'mustBeBinance' to be a boolean.`)
    }
    if (typeof silent !== 'boolean') {
      throw new Error(`Binance connector: Expected option 'silent' to be a boolean.`)
    }
    if (typeof timeout !== 'number') {
      throw new Error(`Binance connector: Expected option 'timeout' to be a number.`)
    }
  }
}
