export const BASE_TOKENS = {
  eth: {
    address: "0x0000000000000000000000000000000000000000",
    symbol: "ETH"
  },
  bnb: {
    address: "0x0000000000000000000000000000000000000000",
    symbol: "BNB"
  }
}
