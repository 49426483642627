<template>
  <div class="ui-loader-overlay">
    <div>
      <img src="/images/loader-icon.svg">
    </div>
  </div>

</template>

<script>
export default {
  name: "UiLoader"
}
</script>

<style lang="scss">
  .ui-loader-overlay {
    top: 0;
    height: calc(100%);
    width: 100%;
    z-index: 2000;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: gray;
    background: #F3F6F9 url(/images/background-wive.svg) top center no-repeat fixed;
    background-size: contain;
    margin: auto;

    & > div {
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }
</style>
