import Vue from 'vue';
import Web3 from 'web3'
import BigNumber from "bignumber.js";
import networks from "@/helpers/networks.json"
import connectors from "@/helpers/connectors.json"
import erc20 from "@/data/abi/erc20.json"
import PoolRegister from "@/data/abi/PoolRegister.json"
import {
  _dateNowPlusEstimatedSeconds,
  checkAllowance,
  getLSConnector,
  getOptionsFromNetworkName,
  removeLSConnector,
  setLSConnector
} from "@/helpers/utils"
import detectEthereumProvider from '@metamask/detect-provider'
import Portis from "@portis/web3";
import {detectBinanceProvider} from "@/helpers/connectors/binance";
import WalletConnectProvider from '@walletconnect/web3-provider'

import {NEXT_STEP, SET_STEPS, UPDATE_ACTIVE_STEP} from "@/store/modules/stepper.module";
import {PRELOAD_BASE_TOKEN} from "@/store/modules/tokens.module";
import {CONTRACT_NAMES} from "@/store/modules/contracts.module";


//action types
export const INIT_WEB_3 = 'initWeb3'
export const CLAIM = 'claim'
export const WITHDRAW = 'withdraw'
export const WITHDRAW_ETH = 'withdrawEth'
export const SWAP_DEFAULT = 'swapDefault'
export const SWAP_DEFAULT_ETH = 'swapDefaultEth'
export const SWAP_DELAYED = 'swapDelayed'
export const SWAP_DELAYED_ETH = 'swapDelayedEth'
export const LIQUIDITY_ADD = 'liquidityAdd'
export const LIQUIDITY_ADD_ETH = 'liquidityAddEth'
export const WEB_3_LOGIN = 'web3Login'
export const WEB_3_LOGOUT = 'web3Logout'
export const ADD_LOCAL_CLAIM = 'addLocalClaim'
export const BIND_DEFAULT = 'bindDefault'
export const BIND_CLAIM = 'bindClaim'



//mutation types
export const CHECK_LIST = 'checkList'
export const CHECK_CLAIM = 'checkClaim'
export const CHECK_REGISTERED = 'checkRegistered'
export const CHECK_DATE = 'checkDate'
export const CHECK_COUNT = 'checkCount'
export const REGISTER_WEB_3 = 'registerWeb3'
export const SET_WEB_3_STATE = 'setWeb3State'

// BIND CONTRACT ADDRESS
export const BIND_ADDR = '0x4776772FD09072d217b12d7c1cE434aF25e981c3'


function _toBN(num, dec) {
  // 1  Number -> BigNumber
  // 2  right side removal instead of rounding (or round with DOWN parameter)
  // 3  after calculations before send data to contract methods we need to take only integer value explicitly
  // TODO avoid troubles with Number switching to BigNumber for every operation
  return BigNumber(BigNumber(num).multipliedBy(Math.pow(10, Number(dec)))).integerValue(BigNumber.ROUND_DOWN)
}

const state = {
  bind: {
    inList: null,
    claimed: null,
    isRegistered: null,
    date: null,
    count: null
  },
  isInjected: false,
  web3Instance: null,
  networkId: null,
  coinbase: null,
  balance: null,
  error: null,
  network: networks[1],
  updateSubscription: false,
  provider: null,
  localClaims: [],
  localSwaps: [],
  localDelayedSwaps: [],
  localDeposits: []
}


const getters = {
  getWalletShortName: state => {
    if (state.coinbase) {
      let f = (str, n) => (str.length > n) ? str.substr(0, n - 1) + '...' : str

      return f(state.coinbase, 10)
    }
    return 'Connect to wallet'
  },
  getWalletNetwork: state => {
    if (state.networkId !== 1 && state.network) {
      return state.network.network
    } else {
      return null
    }
  },
  // eslint-disable-next-line no-unused-vars
  convertFromWei: state => (str, decimals = 18) => {
    if (str) {
      return +str / Math.pow(10, +decimals)
    }
  },
  getEtherscanLink: state => {
    return `${state.network.explorer}/address/${state.coinbase}`
    // return `https://${state.network.network}.etherscan.io/address/${state.coinbase}`
  },
  getEtherscanTxLink: state => txHash => {
    // let baseUrl = `${state.network.network}.`
    // if (baseUrl === 'homestead.') {
    //   baseUrl = ''
    // }
    // return `https://${baseUrl}etherscan.io/tx/${txHash}`
    return `${state.network.explorer}/tx/${txHash}`
  }
}
const actions = {
  async [INIT_WEB_3]({state, commit, dispatch}) {
    commit(SET_WEB_3_STATE, {'isInjected': false})
    const connector = getLSConnector()
    if (connector) {
      await dispatch(WEB_3_LOGIN, connector)
    }
    if (state.provider) {
      let provider = state.provider
      const w3 = new Web3(provider)
      if (w3) {
        const [networkId, accounts] = await Promise.all([
          await w3.eth.getChainId(),
          await w3.eth.getAccounts(),
        ]);
        let coinbase = accounts[0]
        const balance = await w3.eth.getBalance(coinbase, "latest")
        if (!window.lqf) {
          window.lqf = {}
        }
        window.lqf.coinbase = coinbase

        const chosenNetwork = networks[networkId] || networks[56]

        let baseUrl = `${chosenNetwork.network}.`
        if (baseUrl === 'homestead.') {
          baseUrl = ''
        }
        Vue.axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_PROTOCOL + baseUrl + process.env.VUE_APP_BACKEND_DOMAIN}/`

        commit(REGISTER_WEB_3, {
          networkId: networkId,
          coinbase: coinbase,
          balance: balance,
          web3: w3,
          isInjected: true
        });

        await dispatch(PRELOAD_BASE_TOKEN, networkId)

        if (!state.updateSubscription) {
          provider.on('chainChanged', async () => {
            // const dec = state.web3Instance.utils.toDecimal(res)
            // console.debug(dec)
            await dispatch(INIT_WEB_3)
            window.location.reload()
          });
          provider.on('accountsChanged', async () => {
            await dispatch(INIT_WEB_3)
            window.location.reload()
          })
          commit(SET_WEB_3_STATE, {'updateSubscription': true})
        }
      }
    }
  },
  async [WEB_3_LOGIN]({commit}, connector = 'injected') {
    let provider = null
    let config = null
    switch (connector) {
      case "portis":
        // eslint-disable-next-line no-case-declarations
        let networkConfig = getOptionsFromNetworkName(process.env.VUE_APP_PORTIS_NETWORK || connectors.portis.options.network)

        if (networkConfig && networkConfig.rpcUrl !== '') {
          config = {
            nodeUrl: networkConfig.rpcUrl,
            chainId: networkConfig.chainId
          }
        }
        provider = new Portis(
          connectors.portis.options.dappId,
          config ? config : networkConfig.network
        ).provider;
        break
      case "injected":
        provider = await detectEthereumProvider()
        if (provider) {
          provider.autoRefreshOnNetworkChange = false
          await provider.request({method: 'eth_requestAccounts'}, (res) => {
            console.debug('request', res)
          })
        }
        break
      case "binance":
        provider = await detectBinanceProvider()
        if (provider) {
          provider.autoRefreshOnNetworkChange = false
          await provider.request({method: 'eth_requestAccounts'}, (res) => {
            console.debug('eth_requestAccounts', res)
            console.debug('request', res)
          })
        }
        break;
      case "walletconnect":
        provider = new WalletConnectProvider(connectors['walletconnect'].options);
        await provider.enable();
        break;
    }
    if (provider) {
      setLSConnector(connector)
      commit(SET_WEB_3_STATE, {provider: provider})
    }
    return provider
  },
  async [WEB_3_LOGOUT]() {
    removeLSConnector()
    window.location.reload()
  },
  async [CLAIM]({state, rootState, commit}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      try {

        const tokenAddressFrom = data.tokenIn
        const tokenAddressTo = data.tokenOut
        const previousBreakHash = data.previousBreakHash
        const breaks = data.breaks

        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)

        await contract.methods.claimOrder(
          tokenAddressFrom,
          tokenAddressTo,
          previousBreakHash, // minAmountOut,
          breaks, // to
          _dateNowPlusEstimatedSeconds()
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            console.debug(hash)
            var newSwaps = state.localDelayedSwaps
            data.claimInProgress = true
            newSwaps.push(data)
            await commit(SET_WEB_3_STATE, {
              localDelayedSwaps: newSwaps
            })
          })
          .then(function (tx) {
            resolve(tx);
          })

      } catch (e) {
        reject(e);
        var newSwaps = state.localDelayedSwaps
        data.claimFailed = true
        data.claimInProgress = false
        newSwaps.push(data)
        await commit(SET_WEB_3_STATE, {
          localDelayedSwaps: newSwaps
        })
      }
    })
  },
  async [WITHDRAW]({state, rootState, getters, dispatch}, data) {
    if (getters.ifBaseTokenDetected) {
      return await dispatch(WITHDRAW_ETH, data)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      try {
        const tokenAddressFrom = rootState.tokens.tokens[pool.from.symbol].address
        const tokenAddressTo = rootState.tokens.tokens[pool.to.symbol].address

        const tokenStr = `${pool.from.symbol}/${pool.to.symbol} pool tokens`
        const steps = [
          {action: 'Approve', token: tokenStr, status: 'active'},
          {action: 'Transaction'}
        ]
        dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

        const poolContract = await new state.web3Instance.eth.Contract(erc20, pool.id)
        const contractDecimals = await poolContract.methods.decimals().call()

        const liquidity = _toBN(data.poolTokensHandOver, contractDecimals).toFixed()

        const rs = await checkAllowance(poolContract, WALLET, PR_CONTRACT_ADDR, liquidity, contractDecimals)
        if (rs) {
          await poolContract.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)
        await contract.methods.withdraw(
          tokenAddressFrom,
          tokenAddressTo,
          liquidity, // liquidity,
          WALLET, // to
          _dateNowPlusEstimatedSeconds()
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }

    })
  },
  async [WITHDRAW_ETH]({state, rootState, dispatch, getters}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      const tokenStr = `${pool.from.symbol}/${pool.to.symbol} pool tokens`
      const steps = [
        {action: 'Approve', token: tokenStr, status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {

        let otherTokenDirection = 'to'
        if (getters.checkBaseToken(pool.to.symbol)) {
          otherTokenDirection = 'from'
        }

        const tokenAddress = rootState.tokens.tokens[pool[otherTokenDirection].symbol].address

        const poolContract = await new state.web3Instance.eth.Contract(erc20, pool.id)
        const contractDecimals = await poolContract.methods.decimals().call()

        const liquidity = _toBN(data.poolTokensHandOver, contractDecimals).toFixed()

        const rs = await checkAllowance(poolContract, WALLET, PR_CONTRACT_ADDR, liquidity, contractDecimals)
        if (rs) {
          await poolContract.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)

        await contract.methods.withdrawWithETH(
          tokenAddress, //token1
          liquidity, //liquidity
          WALLET, // to
          _dateNowPlusEstimatedSeconds()
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [SWAP_DEFAULT]({state, rootState, dispatch, getters, commit}, data) {
    if (getters.ifBaseTokenDetected) {
      return await dispatch(SWAP_DEFAULT_ETH, data)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      const steps = [
        {action: 'Approve', token: pool.from.symbol, status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const tokenAddressFrom = rootState.tokens.tokens[pool.from.symbol].address
        const tokenAddressTo = rootState.tokens.tokens[pool.to.symbol].address
        const tokenContractFrom = await new state.web3Instance.eth.Contract(erc20, tokenAddressFrom)
        const tokenContractTo = await new state.web3Instance.eth.Contract(erc20, tokenAddressTo)
        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)
        const fromDecimals = await tokenContractFrom.methods.decimals().call()
        const toDecimals = await tokenContractTo.methods.decimals().call()

        let minAmountOut = _toBN(data.minimumReceived, toDecimals)

        console.debug(pool.from.amount, fromDecimals)
        console.debug(pool.to.amount, toDecimals)

        const fromValue = _toBN(pool.from.amount, fromDecimals).integerValue(BigNumber.ROUND_DOWN).toFixed()

        minAmountOut = minAmountOut.integerValue(BigNumber.ROUND_DOWN).toFixed()

        const rs = await checkAllowance(tokenContractFrom, WALLET, PR_CONTRACT_ADDR, fromValue, fromDecimals)
        if (rs) {
          await tokenContractFrom.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        await contract.methods.swap(
          tokenAddressFrom,
          fromValue,
          tokenAddressTo,
          minAmountOut, // minAmountOut,
          WALLET, // to
          _dateNowPlusEstimatedSeconds()
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            var newSwaps = state.localSwaps
            newSwaps.push({
              txHash: hash,
              id: 1,
              orderClaimed: false,
              orderClosed: false,
              timeout: _dateNowPlusEstimatedSeconds(0)
            })
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
            await commit(SET_WEB_3_STATE, {
              localSwaps: newSwaps
            })
          })
          .then(async function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [SWAP_DEFAULT_ETH]({state, rootState, commit, dispatch, getters}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      let steps = []
      if (getters.checkBaseToken(pool.from.symbol)) {
        steps = [{action: 'Transaction', status: 'active'}]
      } else {
        steps = [
          {action: 'Approve', token: pool.from.symbol, status: 'active'},
          {action: 'Transaction'}
        ]
      }

      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {

        let ethTokenDirection = 'from'
        let otherTokenDirection = 'to'
        if (getters.checkBaseToken(pool.to.symbol)) {
          ethTokenDirection = 'to'
          otherTokenDirection = 'from'
        }
        const tokenAddress = rootState.tokens.tokens[pool[otherTokenDirection].symbol].address
        const tokenContract = await new state.web3Instance.eth.Contract(erc20, tokenAddress)
        const decimals = await tokenContract.methods.decimals().call()

        const tokenAmount = _toBN(pool[otherTokenDirection].amount, decimals).toFixed()

        let minAmount = 1
        if (getters.checkBaseToken(pool.from.symbol)) {
          minAmount = _toBN(data.minimumReceived, decimals)
        } else {
          minAmount = _toBN(data.minimumReceived, decimals)
          const rs = await checkAllowance(tokenContract, WALLET, PR_CONTRACT_ADDR, tokenAmount, decimals)
          if (rs) {
            await tokenContract.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
          }
          dispatch(NEXT_STEP, {idx: data.stepUuid})
        }

        minAmount = minAmount.integerValue(BigNumber.ROUND_DOWN).toFixed()
        const ethAmount = _toBN(pool[ethTokenDirection].amount, 18).toFixed()
        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)

        console.debug('minAmount', minAmount)

        if (getters.checkBaseToken(pool.from.symbol)) {
          await contract.methods.swapFromETH(
            tokenAddress,
            minAmount, // minAmountOut,
            WALLET, // to
            _dateNowPlusEstimatedSeconds()
          ).send({from: WALLET, value: ethAmount})
            .on('transactionHash', async function (hash) {
              var newSwaps = state.localSwaps
              newSwaps.push({
                txHash: hash,
                id: 1,
                orderClaimed: false,
                orderClosed: false,
                timeout: _dateNowPlusEstimatedSeconds(0)
              })
              dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
              await commit(SET_WEB_3_STATE, {
                localSwaps: newSwaps
              })
            })
            .then(function (tx) {
              resolve(tx);
            })
          dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
        } else {
          await contract.methods.swapToETH(
            tokenAddress,
            tokenAmount,
            minAmount, // minAmountIn,
            WALLET, // to
            _dateNowPlusEstimatedSeconds()
          ).send({from: WALLET})
            .on('transactionHash', async function (hash) {
              var newSwaps = state.localSwaps
              newSwaps.push({
                txHash: hash,
                id: 1,
                orderClaimed: false,
                orderClosed: false,
                timeout: _dateNowPlusEstimatedSeconds(0)
              })
              dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
              await commit(SET_WEB_3_STATE, {
                localSwaps: newSwaps
              })
            })
            .then(function (tx) {
              resolve(tx);
            })
          dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
        }
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [SWAP_DELAYED]({state, rootState, getters, dispatch, commit}, data) {
    if (getters.ifBaseTokenDetected) {
      return await dispatch(SWAP_DELAYED_ETH, data)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      const steps = [
        {action: 'Approve', token: pool.from.symbol, status: 'active'},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const tokenAddressFrom = rootState.tokens.tokens[pool.from.symbol].address
        const tokenAddressTo = rootState.tokens.tokens[pool.to.symbol].address
        const tokenContractFrom = await new state.web3Instance.eth.Contract(erc20, tokenAddressFrom)
        const tokenContractTo = await new state.web3Instance.eth.Contract(erc20, tokenAddressTo)
        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)
        const fromDecimals = await tokenContractFrom.methods.decimals().call()
        const toDecimals = await tokenContractTo.methods.decimals().call()

        let minAmount = _toBN(pool.from.amount, fromDecimals)
        let minAmountOut = _toBN(data.minimumReceived, toDecimals)

        minAmount = minAmount.integerValue(BigNumber.ROUND_DOWN).toFixed()
        minAmountOut = minAmountOut.integerValue(BigNumber.ROUND_DOWN).toFixed()

        const rs = await checkAllowance(tokenContractFrom, WALLET, PR_CONTRACT_ADDR, minAmount, fromDecimals)
        if (rs) {
          await tokenContractFrom.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        const period = Number(data.acceptTime) * 60

        await contract.methods.delayedSwap(
          tokenAddressFrom, // tokenIn
          minAmount, // amountIn
          tokenAddressTo, // tokenOut
          minAmountOut, // minAmountOut
          WALLET, // wallet addr
          _dateNowPlusEstimatedSeconds(period),
          0,  // prevByStopLoss
          0 // prevByTimeout
        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            var newSwaps = state.localSwaps
            newSwaps.push({
              txHash: hash,
              id: 1,
              orderClaimed: false,
              orderClosed: false,
              timeout: _dateNowPlusEstimatedSeconds(0)
            })
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
            await commit(SET_WEB_3_STATE, {
              localSwaps: newSwaps
            })
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [SWAP_DELAYED_ETH]({state, rootState, commit, dispatch, getters}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = Object.assign({}, rootState.pools);
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      let steps = []
      if (getters.checkBaseToken(pool.from.symbol)) {
        steps = [{action: 'Transaction', status: 'active'}]
      } else {
        steps = [
          {action: 'Approve', token: pool.from.symbol, status: 'active'},
          {action: 'Transaction'}
        ]
      }

      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {

        let ethTokenDirection = 'from'
        let otherTokenDirection = 'to'
        if (getters.checkBaseToken(pool.to.symbol)) {
          ethTokenDirection = 'to'
          otherTokenDirection = 'from'
        }

        const tokenAddress = rootState.tokens.tokens[pool[otherTokenDirection].symbol].address
        const tokenContract = await new state.web3Instance.eth.Contract(erc20, tokenAddress)
        const decimals = await tokenContract.methods.decimals().call()

        const tokenAmount = _toBN(pool[otherTokenDirection].amount, decimals).integerValue(BigNumber.ROUND_DOWN).toFixed()

        let minAmount = 1
        if (getters.checkBaseToken(pool.from.symbol)) {
          minAmount = _toBN(data.minimumReceived, decimals)
        } else {
          minAmount = _toBN(data.minimumReceived, 18)
          const rs = await checkAllowance(tokenContract, WALLET, PR_CONTRACT_ADDR, tokenAmount, decimals)
          if (rs) {
            await tokenContract.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
          }
          dispatch(NEXT_STEP, {idx: data.stepUuid})
        }

        const ethAmount = _toBN(pool[ethTokenDirection].amount, 18).integerValue(BigNumber.ROUND_DOWN).toFixed()

        minAmount = minAmount.integerValue(BigNumber.ROUND_DOWN).toFixed()

        const contract = await new state.web3Instance.eth.Contract(rootState.contracts.pool_register.abi, PR_CONTRACT_ADDR)
        const period = Number(data.acceptTime) * 60

        if (getters.checkBaseToken(pool.from.symbol)) {
          await contract.methods.delayedSwapFromETH(
            tokenAddress, // address tokenOut,
            minAmount, // uint minAmountOut,
            WALLET, // address to,
            _dateNowPlusEstimatedSeconds(period), // uint timeout,
            0, // uint prevByStopLoss,
            0 // uint prevByTimeout
          ).send({from: WALLET, value: ethAmount})
            .on('transactionHash', async function (hash) {
              var newSwaps = state.localSwaps
              newSwaps.push({
                txHash: hash,
                id: 1,
                orderClaimed: false,
                orderClosed: false,
                timeout: _dateNowPlusEstimatedSeconds(0)
              })
              dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
              await commit(SET_WEB_3_STATE, {
                localSwaps: newSwaps
              })
            })
            .then(function (tx) {
              resolve(tx);
            })
          dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
        } else {
          await contract.methods.delayedSwapToETH(
            tokenAddress, // address tokenIn,
            tokenAmount, // uint amountIn,
            minAmount, // uint minAmountOut,
            WALLET, // address to,
            _dateNowPlusEstimatedSeconds(period), // uint timeout,
            0, // uint prevByStopLoss,
            0 // uint prevByTimeout
          ).send({from: WALLET}).then(function (tx) {
            resolve(tx);
          })
          dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
        }
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },


  async [BIND_DEFAULT]({state, rootState}) {

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;
      try {
        const contract = await new state.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        // await contract.methods.check.call({from: WALLET})
        await contract.methods.register()
        .send({from: WALLET})
          .then(async function (res) {
            console.debug('REGISTERED', res)
            location.reload()
          })
          .then(async function (inList) {
            resolve(inList);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [BIND_CLAIM]({state, rootState}) {

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        const contract = await new state.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)



        // await contract.methods.check.call({from: WALLET})
        await contract.methods.claim()
        .send({from: WALLET})
          .then(async function (res) {
            console.debug('CLAIMED', res)
            location.reload()
          })
          .then(async function (inList) {
            resolve(inList);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [CHECK_LIST]({state, commit, rootState} ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        // BIND_TODO
        // const contract = await new state.web3Instance.eth.Contract(rootState.contracts.bind_register.abi, PR_CONTRACT_ADDR)
        const contract = await new rootState.web3.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        await contract.methods.checkApproved(WALLET).call({from: WALLET})
          .then(async function (inList) {
              var bind = state['bind']
              bind['inList']= inList
              console.debug('GLOBAL_STATE_APPROVED', inList,state)
            await commit(SET_WEB_3_STATE, {
              bind: bind
            })
          })
          .then(async function (inList) {
            resolve(inList);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [CHECK_REGISTERED]({state, commit, rootState} ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        // BIND_TODO
        // const contract = await new state.web3Instance.eth.Contract(rootState.contracts.bind_register.abi, PR_CONTRACT_ADDR)
        const contract = await new rootState.web3.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        await contract.methods.checkRegistered(WALLET).call({from: WALLET})
          .then(async function (isRegistered) {
              var bind = state['bind']
              bind['isRegistered']= isRegistered
              console.debug('GLOBAL_STATE_REGISTERED', isRegistered,state)
            await commit(SET_WEB_3_STATE, {
              bind: bind
            })
          })
          .then(async function (inList) {
            resolve(inList);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [CHECK_CLAIM]({state, commit, rootState} ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        // BIND_TODO
        // const contract = await new state.web3Instance.eth.Contract(rootState.contracts.bind_register.abi, PR_CONTRACT_ADDR)
        const contract = await new rootState.web3.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        await contract.methods.checkClaimed(WALLET).call({from: WALLET})
          .then(async function (claimed) {
              var bind = state['bind']
              bind['claimed']= claimed
              console.debug('GLOBAL_STATE_CLAIMED', claimed,state)
            await commit(SET_WEB_3_STATE, {
              bind: bind
            })
          })
          .then(async function (claimed) {
            resolve(claimed);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [CHECK_DATE]({state, commit, rootState} ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        // BIND_TODO
        // const contract = await new state.web3Instance.eth.Contract(rootState.contracts.bind_register.abi, PR_CONTRACT_ADDR)
        const contract = await new rootState.web3.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        await contract.methods.registerDate().call({from: WALLET})
          .then(async function (date) {
              var bind = state['bind']
              bind['date']= date
              console.debug('GLOBAL_STATE_DATE',date, new Date(1000 * date),state)
            await commit(SET_WEB_3_STATE, {
              bind: bind
            })
          })
          .then(async function (date) {
            resolve(date);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },

  async [CHECK_COUNT]({state, commit, rootState} ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].address;
      const PR_CONTRACT_ABI = rootState.contracts[CONTRACT_NAMES.LQF_GIVEAWAY].abi;

      try {
        // BIND_TODO
        // const contract = await new state.web3Instance.eth.Contract(rootState.contracts.bind_register.abi, PR_CONTRACT_ADDR)
        const contract = await new rootState.web3.web3Instance.eth.Contract(PR_CONTRACT_ABI, PR_CONTRACT_ADDR)

        await contract.methods.getRegisteredCount().call({from: WALLET})
          .then(async function (count) {
              var bind = state['bind']
              bind['count']= count
              console.debug('GLOBAL_STATE_COUNT',count, 100000 / count,state)
            await commit(SET_WEB_3_STATE, {
              bind: bind
            })
          })
          .then(async function (date) {
            resolve(date);
          })
      } catch (e) {
        console.log(e);
      }
    })
  },


  async [LIQUIDITY_ADD]({state, rootState, getters, dispatch, commit}, data) {
    if (getters.ifBaseTokenDetected) {
      return await dispatch(LIQUIDITY_ADD_ETH, data)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = rootState.pools;
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      const steps = [
        {action: 'Approve', token: pool.from.symbol, status: 'active'},
        {action: 'Approve', token: pool.to.symbol},
        {action: 'Transaction'}
      ]
      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const tokenAddressFrom = rootState.tokens.tokens[pool.from.symbol].address
        const tokenAddressTo = rootState.tokens.tokens[pool.to.symbol].address

        const liquifiContract = await new state.web3Instance.eth.Contract(
          PoolRegister.abi,
          PR_CONTRACT_ADDR
        )
        const tokenFromContract = await new state.web3Instance.eth.Contract(erc20, tokenAddressFrom)
        const tokenToContract = await new state.web3Instance.eth.Contract(erc20, tokenAddressTo)

        const fromDecimals = await tokenFromContract.methods.decimals().call()
        const toDecimals = await tokenToContract.methods.decimals().call()

        const fromAmount = _toBN(pool.from.amount, fromDecimals).integerValue(BigNumber.ROUND_DOWN).toFixed()
        const toAmount = _toBN(pool.to.amount, toDecimals).integerValue(BigNumber.ROUND_DOWN).toFixed()

        const rsFrom = await checkAllowance(tokenFromContract, WALLET, PR_CONTRACT_ADDR, fromAmount, fromDecimals)
        if (rsFrom) {
          await tokenFromContract.methods.approve(PR_CONTRACT_ADDR, rsFrom).send({from: WALLET})
        }
        await dispatch(NEXT_STEP, {idx: data.stepUuid})

        const rsTo = await checkAllowance(tokenToContract, WALLET, PR_CONTRACT_ADDR, toAmount, toDecimals)
        if (rsTo) {
          await tokenToContract.methods.approve(PR_CONTRACT_ADDR, rsTo).send({from: WALLET})
        }
        await dispatch(NEXT_STEP, {idx: data.stepUuid})

        await liquifiContract.methods.deposit(
          tokenAddressFrom,
          fromAmount,
          tokenAddressTo,
          toAmount,
          WALLET, // to
          _dateNowPlusEstimatedSeconds()

        ).send({from: WALLET})
          .on('transactionHash', async function (hash) {
            var newDeps = state.localDeposits
            newDeps.push({
              txHash: hash,
              id: 1,
              orderClaimed: false,
              orderClosed: false,
              timeout: _dateNowPlusEstimatedSeconds(0)
            })
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
            await commit(SET_WEB_3_STATE, {
              localDeposits: newDeps
            })
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  },
  async [LIQUIDITY_ADD_ETH]({state, rootState, commit, dispatch, getters}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const pool = rootState.pools;
      const WALLET = state.coinbase
      const PR_CONTRACT_ADDR = rootState.contracts.pool_register.address;

      let steps = []
      if (getters.checkBaseToken(pool.to.symbol)) {
        steps = [
          {action: 'Approve', token: pool.from.symbol, status: 'active'},
          {action: 'Transaction'}
        ]
      } else {
        steps = [
          {action: 'Approve', token: pool.to.symbol, status: 'active'},
          {action: 'Transaction'}
        ]
      }

      dispatch(SET_STEPS, {idx: data.stepUuid, steps: steps})

      try {
        const liquifiContract = await new state.web3Instance.eth.Contract(
          PoolRegister.abi,
          PR_CONTRACT_ADDR
        )

        let ethTokenDirection = 'from'
        let otherTokenDirection = 'to'
        if (getters.checkBaseToken(pool.to.symbol)) {
          ethTokenDirection = 'to'
          otherTokenDirection = 'from'
        }

        const tokenAddress = rootState.tokens.tokens[pool[otherTokenDirection].symbol].address
        const tokenContract = await new state.web3Instance.eth.Contract(erc20, tokenAddress)
        const decimals = await tokenContract.methods.decimals().call()

        let tokenAmount = _toBN(pool[otherTokenDirection].amount, decimals).integerValue(BigNumber.ROUND_DOWN).toFixed()
        let ethAmount = _toBN(pool[ethTokenDirection].amount, 18).integerValue(BigNumber.ROUND_DOWN).toFixed()

        const rs = await checkAllowance(tokenContract, WALLET, PR_CONTRACT_ADDR, tokenAmount, decimals)
        if (rs) {
          await tokenContract.methods.approve(PR_CONTRACT_ADDR, rs).send({from: WALLET})
        }
        dispatch(NEXT_STEP, {idx: data.stepUuid})

        await liquifiContract.methods.depositWithETH(
          tokenAddress,
          tokenAmount,
          WALLET, // to
          _dateNowPlusEstimatedSeconds()
        ).send({from: WALLET, value: ethAmount})
          .on('transactionHash', async function (hash) {
            var newSwaps = state.localSwaps
            newSwaps.push({
              txHash: hash,
              id: 1,
              orderClaimed: false,
              orderClosed: false,
              timeout: _dateNowPlusEstimatedSeconds(0)
            })
            dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {txHash: hash}})
            await commit(SET_WEB_3_STATE, {
              localSwaps: newSwaps
            })
          })
          .then(function (tx) {
            resolve(tx);
          })
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'success'}})
      } catch (e) {
        dispatch(UPDATE_ACTIVE_STEP, {idx: data.stepUuid, step: {status: 'error', errorMessage: e.message}})
        reject(e);
      }
    })
  }
}
const mutations = {
  [REGISTER_WEB_3](state, payload) {
    Vue.set(state, 'coinbase', payload.coinbase)
    Vue.set(state, 'networkId', payload.networkId)
    Vue.set(state, 'balance', parseInt(payload.balance, 10))
    Vue.set(state, 'web3Instance', payload.web3)
    Vue.set(state, 'network', networks[payload.networkId] || networks[1])
    Vue.set(state, 'isInjected', true)
  },
  [SET_WEB_3_STATE](state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key]);
    });
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
