import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import ApiService from "@/store/services/api.service";
import IconCrypto from "vue-cryptocurrency-icons";
import Clipboard from 'v-clipboard'
import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import InfiniteLoading from 'vue-infinite-loading';
import UUID from "vue-uuid";

Vue.use(Clipboard)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueApollo)

// IconCrypto
Vue.use(IconCrypto)

//Init Api Service
ApiService.init()

Vue.use(InfiniteLoading);

Vue.use(UUID);

Vue.config.devtools = true;
Vue.config.productionTip = false

const base = 'https://api.thegraph.com/subgraphs/name/liquifi-project/liquifi'
const urls = {
  'main': base,
  'rinkeby': base + '-rinkeby',
  'ropsten': base + '-ropsten',
  'bsc': base + '-bsc'
}
// HTTP connection to the API
const mainnetUrl = new HttpLink({
  uri: urls['main']
})

const rinkebyUrl = new HttpLink({
  uri: urls['rinkeby']
})

const ropstenUrl = new HttpLink({
  uri: urls['ropsten']
})

const bscUrl = new HttpLink({
  uri: urls['bsc']
})


// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const mainnetClient = new ApolloClient({
  link: mainnetUrl,
  cache
})

const rinkebyClient = new ApolloClient({
  link: rinkebyUrl,
  cache
})

const ropstenClient = new ApolloClient({
  link: ropstenUrl,
  cache
})

const bscClient = new ApolloClient({
  link: bscUrl,
  cache
})


const apolloProvider = new VueApollo({
  defaultClient: rinkebyClient,
  clients: {
    mainnetClient,
    rinkebyClient,
    ropstenClient,
    bscClient
  }
})

new Vue({
  store,
  apolloProvider,
  router,
  render: h => h(App),
}).$mount('#app')
