import Vue from "vue"
import ApiService from "@/store/services/api.service";
import BigNumber from "bignumber.js";

//action types
export const RESET_POOLS_STATE = 'resetPoolsState'
export const HTTP_GET_FEE_RATIO = 'httpGetFeeRatio'
export const HTTP_GET_POOLS = 'httpGetPools'
export const HTTP_GET_POOL_ID_BY_TOKEN_PAIR = 'httpGetPoolIdByTokenPair'
export const HTTP_GET_TOKEN_BALANCES_BY_ID = 'httpGetTokenBalancesById'
export const HTTP_GET_POOL_TOKENS_TOTAL = 'httpGetPoolTokensTotal'
export const HTTP_GET_PROVIDED_POOLS = 'httpGetProvidedPools'
export const HTTP_GET_OUTPUT_PREDICTIONS = 'httpGetOutputPredictions'
export const HTTP_GET_POOLS_DATA = 'httpGetPoolsData'
export const HTTP_GET_POOLS_COUNT = 'httpGetPoolsCount'

//mutation types
export const SET_POOLS_STATE = 'setPoolsState'
export const SET_POOLS_TOKEN_DATA = 'setPoolsTokenData'
export const SWAP_FROM_TO = 'swapFromTo'

const state = {
  id: '',
  from: {symbol: '', amount: new BigNumber(0), balance: new BigNumber(0)},
  to: {symbol: '', amount: new BigNumber(0), balance: new BigNumber(0)},
  fee_ratio: new BigNumber(0),
  pools: [],
  total_pool_tokens: new BigNumber(0),
  providedPools: [],
  predictions: []
}
const getters = {
  isTokenHasId(state) {
    return state.id !== ''
  },
  isTokensEqual(state) {
    return (state.from.symbol === state.to.symbol)
      && (state.from.symbol !== '' && state.to.symbol !== '')
  },
  isTokensHasName(state) {
    return state.from.symbol !== '' && state.to.symbol !== ''
  },
  isTokensHasAmount(state) {
    return state.from.amount > 0 && state.to.amount > 0
  },
  getProvidedPool: (state, getters) => {
    if (!getters.isTokensHasName) {
      return null;
    }
    const inPool = [state.from.symbol, state.to.symbol]
    let result = state.providedPools.filter(item =>
      [...new Set([...item.pair, ...inPool])].length === 2)[0]
    if (!result) {
      result = state.providedPools.filter(item => item.id === state.id)[0]
    }
    return result
  },
  existsInProviderPools: (state, getters) => {
    return !!getters.getProvidedPool
  },
  ifBaseTokenDetected: (state, getters) => {
    const baseTokenSymbol = getters.getBaseToken.symbol
    return baseTokenSymbol === state.from.symbol || baseTokenSymbol === state.to.symbol
  }
}
const actions = {
  async [RESET_POOLS_STATE]({commit}) {
    await new Promise(resolve => {
      const pool = {
        id: '',
        from: {symbol: '', amount: new BigNumber(0), balance: new BigNumber(0)},
        to: {symbol: '', amount: new BigNumber(0), balance: 0},
        fee_ratio: new BigNumber(0),
        total_pool_tokens: new BigNumber(0),
        providedPools: [],
        predictions: []
      }
      commit(SET_POOLS_STATE, pool)
      resolve()
    })
  },
  async [HTTP_GET_FEE_RATIO]({state, commit}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get(`pools/${state.id}`, 'fee_ratio')
        .then(({data}) => {
          commit(SET_POOLS_STATE, {fee_ratio: new BigNumber(data.fee_ratio)})
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [HTTP_GET_POOLS]({commit}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get('', 'pools')
        .then(({data}) => {
          commit(SET_POOLS_STATE, {pools: data})
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [HTTP_GET_POOL_ID_BY_TOKEN_PAIR]({state, commit}) {
    commit(SET_POOLS_STATE, {id: '', fee_ratio: new BigNumber(0)})
    commit(SET_POOLS_TOKEN_DATA, {dst: 'from', data: {balance: new BigNumber(0)}})
    commit(SET_POOLS_TOKEN_DATA, {dst: 'to', data: {balance: new BigNumber(0)}})
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get('', `pools?token_pair=${state.from.symbol},${state.to.symbol}`)
        .then(({data}) => {
          commit(SET_POOLS_STATE, {id: data.id})
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [HTTP_GET_TOKEN_BALANCES_BY_ID]({state, commit}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // await ApiService.get(`pools/${state.id}`, 'tokens_balance')
      await ApiService.get(`pools`, `tokens_balance?token_pair=${state.from.symbol},${state.to.symbol}`)
        .then(({data}) => {
          commit(SET_POOLS_TOKEN_DATA, {dst: 'from', data: {balance: new BigNumber(data[state.from.symbol])}})
          commit(SET_POOLS_TOKEN_DATA, {dst: 'to', data: {balance: new BigNumber(data[state.to.symbol])}})

          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  async [HTTP_GET_POOL_TOKENS_TOTAL]({commit}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get(`pools/${state.id}`, 'total_pool_tokens')
        .then(({data}) => {
          commit(SET_POOLS_STATE, {total_pool_tokens: new BigNumber(data.amount)})
          resolve(data)
        })
        .catch(({response}) => reject(response))
    })
  },
  async [HTTP_GET_PROVIDED_POOLS]({commit, rootState}) {
    commit(SET_POOLS_STATE, {providedPools: []})
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get(`/providers/${rootState.web3.coinbase}`, 'pool_data')
        .then(({data}) => {
          commit(SET_POOLS_STATE, {providedPools: data})
          resolve(data)
        })
        .catch(({response}) => {
          reject(response)
        })
    })
  },
  async [HTTP_GET_OUTPUT_PREDICTIONS]({commit, rootState, state, getters}, data) {
    commit(SET_POOLS_STATE, {predictions: []})
    let acceptT = data.acceptTime.map(time => {
      return time * 60
    })

    const baseToken = rootState.tokens.baseToken
    const tFrom = getters.checkBaseToken(state.from.symbol) ? baseToken : rootState.tokens.tokens[state.from.symbol]
    const tTo = getters.checkBaseToken(state.to.symbol) ? baseToken : rootState.tokens.tokens[state.to.symbol]

    let params = {
      "token_in": tFrom.address,
      "token_out": tTo.address,
      "amount_in": +state.from.amount,
      "min_output": +data.minimumReceived,
      "periods": [...acceptT]
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.post(`pools/${state.id}/output_prediction`, params)
        .then(({data}) => {
          commit(SET_POOLS_STATE, {predictions: data})
          resolve(data)
        })
        .catch(({response}) => {
          reject(response)
        })
    })
  },
  // eslint-disable-next-line no-empty-pattern
  async [HTTP_GET_POOLS_DATA]({}, params) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.query('pools_data', {params})
        .then(({data}) => {
          resolve(data)
        })
        .catch((response) => reject(response))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  async [HTTP_GET_POOLS_COUNT]({}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await ApiService.get(`pools`, 'count')
        .then(({data}) => {
          resolve(data)
        })
        .catch(({response}) => reject(response))
    })
  },
}
const mutations = {
  [SET_POOLS_STATE](state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key])
    });
  },
  [SET_POOLS_TOKEN_DATA](state, payload) {
    Object.keys(payload.data).forEach(key => {
      Vue.set(state[payload.dst], key, payload.data[key])
    });
  },
  [SWAP_FROM_TO](state) {
    [state.from, state.to] = [state.to, state.from]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
