<template>
  <div id="app">
    <ui-loader v-if="!$store.state.ui.init"></ui-loader>
    <div>
      <router-view/>
    </div>
  </div>

</template>

<script>

import UiLoader from "@/components/ui/UiLoader";
import {mapActions, mapMutations} from "vuex";
import {INIT_UI, LOADING_UI} from "@/store/modules/ui.module";
import {GET_WALLET_BALANCES, LOAD_TOKENS_FROM_SELECTED_LIST, LOAD_TOKENS_LISTS} from "@/store/modules/tokens.module";
import {INIT_CONTRACTS, HTTP_GET_CONTRACT_METADATA} from "@/store/modules/contracts.module";
import {HTTP_GET_POOLS} from "@/store/modules/pools.module";
import {INIT_WEB_3} from "@/store/modules/web3.module";
import {CHECK_LIST, CHECK_CLAIM, CHECK_REGISTERED, CHECK_DATE, CHECK_COUNT} from "@/store/modules/web3.module";

export default {
  name: 'Liquifi',
  components: {
    UiLoader
  },
  watch: {
    '$store.state.web3.isInjected': {
      handler: async function (val) {
        if (val) {
          await this.loadTokensLists()
          await this.loadTokensFromSelectedList();
          // await this.getWalletBalances()
          // await this.httpGetContractMetadata()
          // await this.httpGetPools()
          this[INIT_CONTRACTS]();
          // NOTE: FSDEX-510 - current contact can not do it.
          // await this[CHECK_LIST]();
          await this[CHECK_CLAIM]();
          await this[CHECK_REGISTERED]();
          await this[CHECK_DATE]();
          await this[CHECK_COUNT]();
        }
      },
    },
    // '$store.state.web3.provider': {
    //   handler: async function (val) {
    //     if (val) {
    //       await this.initWeb3()
    //     }
    //   },
    // },
  },
  async mounted() {
    this.initUi()
    this.$nextTick(() => {
      setTimeout(()=>{
        this.$store.commit('setUiState', {init: true})
      }, 1500)
    });
  },
  methods: {
    ...mapActions([
      INIT_UI,
      GET_WALLET_BALANCES,
      LOAD_TOKENS_LISTS,
      LOAD_TOKENS_FROM_SELECTED_LIST,
      INIT_CONTRACTS,
      HTTP_GET_CONTRACT_METADATA,
      HTTP_GET_POOLS,
      INIT_WEB_3,
      LOADING_UI,
      CHECK_LIST,
      CHECK_CLAIM,
      CHECK_REGISTERED,
      CHECK_DATE,
      CHECK_COUNT
    ]),
    ...mapMutations({
      setUiState: 'SET_UI_STATE'
    })
  }
}
</script>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 150px);
  }
</style>
